<template>
  <v-multiselect-listbox
    v-model="selected"
    :options="options"
    :reduce-display-property="reduceDisplay"
    :reduce-value-property="reduceValue"
    :search-options-placeholder="searchOptionsPlaceholder"
    :selected-options-placeholder="selectedOptionsPlaceholder"
    :selected-no-options-text="selectedNoOptionsText"
    :no-options-text="noOptionsText"
  />
</template>

<script>
import VMultiselectListbox from "vue-multiselect-listbox";

export default {
  props: {
    value: Array,
    options: Array,
    reduceDisplay: Function,
    reduceValue: Function,
    searchOptionsPlaceholder: String,
    selectedOptionsPlaceholder: String,
    selectedNoOptionsText: String,
    noOptionsText: String,
  },
  components: {
    VMultiselectListbox,
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
    selected(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="stylus">
.v-application--wrap {
  min-height: 0px;
}

.msl-searchable-list__items {
  background-color: #0000000f !important;
  border: 0px !important;
  border-radius: 15px;
}

.msl-searchable-list__item {
  border-bottom: 1px solid #8b8b8b99 !important;
}

.msl-searchable-list__no-item {
  color: #00000099 !important;
}
</style>
