<template>
  <BaseDialog
    :isValid="isValid"
    :isLoading="isLoading"
    title="Adicionar Playlist"
    @handlerSave="handleFormSubmission"
    width="70%"
  >
    <v-app>
      <validation-observer ref="observer">
        <v-row class="pa-6">
          <v-col cols="12">
            <v-text-field
              label="Nome"
              v-model="form.name"
              fieldLayout="filled"
              required
              rounded
              filled
              clearable
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <base-date-picker
                  :value="form.initialDate"
                  @input="form.initialDate = $event"
                  placeholder="Data Inicial"
                />
              </v-col>
              <v-col cols="6">
                <base-date-picker
                  :value="form.finalDate"
                  @input="form.finalDate = $event"
                  placeholder="Data Final"
                />
              </v-col>

              <v-col cols="11">
                <v-combobox
                  chips
                  v-model="form.contents"
                  :items="contents.data?.docs"
                  :value-type="Array"
                  item-text="name"
                  item-value="_id"
                  label="Conteudos"
                  rounded
                  filled
                  clearable
                  hide-details
                  dense
                  multiple
                >
                </v-combobox>
              </v-col>
              <v-col cols>
                <v-btn color="primary" class="mt-5" @click="newContent = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mr-auto">
                <BaseMultiselectListbox
                  :options="cities"
                  :value="selectedCities"
                  @input="handleCities"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <BaseMultiselectListbox
                  :options="terminals.data.docs"
                  v-model="form.terminals"
                  :reduce-display="(option) => option?.name"
                  :reduce-value="(option) => option?._id"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <ContentCreate
          v-if="newContent"
          :component="true"
          @sendedNewContent="createdContent"
          @closeModal="newContent = false"
        />
      </validation-observer>
    </v-app>
  </BaseDialog>
</template>

<script>
import BaseMultiselectListbox from "../../components/UI/BaseMultiselectListbox";
import BaseDialog from "../../components/UI/BaseDialog";
import BaseDatePicker from "../../components/UI/BaseDatePicker";

import ContentCreate from "../contents/create";

import ContentService from "../contents/service";
import TerminalService from "../terminal/service";
import PlaylistService from "./service";

import { ValidationObserver } from "vee-validate";
import { cloneDeep } from "lodash";

export default {
  props: ["component"],
  name: "CreatePlaylist",

  components: {
    ValidationObserver,
    BaseDialog,
    BaseDatePicker,
    BaseMultiselectListbox,
    ContentCreate,
  },

  data: () => ({
    isValid: true,
    isLoading: false,
    typeForm: "",
    terminals: [],
    cities: [],
    newContent: false,

    form: {
      contents: [],
      initialDate: "",
      finalDate: "",
      terminals: [],
    },
    contents: [],
    selectedTerminals: [],
    selectedCities: [],
    fileName: "",
  }),

  methods: {
    async handleCities(selectedCities) {
      this.selectedCities = selectedCities;
      this.terminals = await TerminalService.getTerminals({
        "location.state": selectedCities,
        pagination: false,
      });
    },
    async handleFormSubmission() {
      this.isLoading = true;
      const playlist = await this.submitPlaylist(
        this.typeForm === "CreatePlaylists"
      );
      this.$router.push({ name: "Playlists" });
      this.$emit("created", { playlist, typeForm: this.typeForm });
      this.isLoading = false;
    },

    async submitPlaylist(isCreate) {
      try {
        const playlist = isCreate
          ? await PlaylistService.createPlaylist(this.form)
          : await PlaylistService.updatePlaylist(
              this.$route.params.id,
              this.form
            );
        return playlist;
      } catch (error) {
        console.error(error);
      }
    },

    async createContent() {
      this.$router.push({ name: "CreateContent" });
    },

    async closeModal() {
      this.component
        ? this.$emit("closeModal", true)
        : this.$router.push({ name: "Playlists" });
    },

    async createdContent() {
      this.contents = await ContentService.getContents({ pagination: false });
      this.newContent = false;
    },
  },
  async mounted() {
    this.typeForm = this.$route.name;
    this.contents = await ContentService.getContents({ pagination: false });
    this.cities = await TerminalService.getStates();
    this.terminals = await TerminalService.getTerminals({ pagination: false });

    if (this.typeForm === "EditPlaylists") {
      const playlist = await PlaylistService.getPlaylistById(
        this.$route.params.id
      );
      this.form = cloneDeep(playlist.data);

      const terminalIds = this.terminals.data.docs.map(
        (terminal) => terminal._id
      );

      const commonTerminals = terminalIds.filter((id) => {
        return playlist.data.terminals.includes(id);
      });

      this.form.terminals = commonTerminals;
    }
  },
};
</script>
