<template>
  <BaseDialog
    :isValid="isValid"
    :isLoading="isLoading"
    title="Adicionar Conteudo"
    @handlerSave="verifyTypeForm"
    width="60%"
  >
    <v-app>
      <validation-observer ref="observer">
        <v-row class="pa-6">
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Data de inicio"
                      v-model="content.initialDate"
                      fieldLayout="filled"
                      required
                      rounded
                      filled
                      clearable
                      hide-details
                      dense
                      v-bind="attrs"
                      @blur="date = parseDate(content.initialDate)"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                    locale="pt-br"
                    scrollable
                  >
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="menu = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Data de termino"
                      v-model="content.finalDate"
                      required
                      rounded
                      filled
                      clearable
                      hide-details
                      dense
                      v-bind="attrs"
                      @blur="date2 = parseDate(content.finalDate)"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    no-title
                    @input="menu2 = false"
                    locale="pt-br"
                    scrollable
                  >
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="menu2 = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu2.save(date2)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="auto">
                <v-radio-group v-model="content.position" row>
                  <v-radio label="Início" value="initial"></v-radio>
                  <v-radio label="Aleatório" value="random"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="auto">
                <v-text-field
                  v-model="content.amount"
                  hide-details
                  filled
                  dense
                  single-line
                  label="Quantidade *"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-Input
                  label="Arquivo *"
                  v-model="fileName"
                  show-size
                  counter
                  rounded
                  dense
                  small-chips
                  multiple
                  filled
                  accept="video/*"
                  clearable
                  :prepend-icon="null"
                  @change="handleFile"
                >
                  <!-- <template v-slot:selection="{ file }">
                    <v-chip size="small">
                      {{ file.name }}
                      +
                    </v-chip>
                  </template> -->
                </v-file-Input>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mr-auto">
                <v-multiselect-listbox
                  :options="cities"
                  :value="selectedCities"
                  @input="handleCities"
                  search-options-placeholder="Regiões :"
                  selected-options-placeholder="Regiões selecionados :"
                  selected-no-options-text="Sem cidades/estados selecionados"
                  no-options-text="Sem cidades/estados para selecionar"
                >
                  > ></v-multiselect-listbox
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-multiselect-listbox
                  v-if="terminals.data.docs.length > 0"
                  :options="terminals.data.docs"
                  v-model="selectedTerminals"
                  :reduce-display-property="(option) => option.name"
                  :reduce-value-property="(option) => option._id"
                  search-options-placeholder="Terminais :"
                  selected-options-placeholder="Terminais Selecionados :"
                  selected-no-options-text="Sem terminais selecionados"
                  no-options-text="Sem terminais para selecionar"
                >
                  > ></v-multiselect-listbox
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                v-for="content in form.contents"
                :key="content.name"
              >
                <Content
                  height="80px"
                  :content="content"
                  :eye="false"
                  :label="false"
                />
                <v-progress-linear
                  :value="content.progress"
                  height="10"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="6" v-if="content.file">
          <video width="100%" controls="controls">
            <source :src="content.file" type="video/mp4" />
          </video>
          <v-img v-model="content.fileName" />
        </v-col> -->
        </v-row>
      </validation-observer>
    </v-app>
  </BaseDialog>
</template>

<style lang="stylus" scoped>
::v-deep .v-application--wrap {
  min-height: 0px;
}

::v-deep .v-dialog {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

::v-deep .msl-searchable-list__items {
  background-color: #0000000f !important;
  border: 0px !important;
  border-radius: 15px;
}

::v-deep .msl-searchable-list__item {
  border-bottom: 1px solid #8b8b8b99 !important;
}

::v-deep .msl-searchable-list__no-item {
  color: #00000099 !important;
}
</style>

<script>
import ContentService from "./service";
import { ValidationObserver } from "vee-validate";
// import BaseInputValidator from "../../components/UI/BaseInputValidator";
import { cloneDeep } from "lodash";
import BaseDialog from "../../components/UI/BaseDialog";
import vMultiselectListbox from "vue-multiselect-listbox";

import Content from "../playlists/_components/PlaylistContent";

import TerminalService from "../terminal/service";

export default {
  props: ["component"],
  name: "CreateContent",
  data: (vm) => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    initialDate: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    finalDate: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    content: {
      name: "",
      file: "",
      initialDate: "",
      finalDate: "",
      thumbnail: "",
      type: "VIDEO",
      duration: "",
      progress: 0,
      position: "random",
      amount: 0,
      terminals: [],
    },

    min: 0,
    max: 10,

    colors: [""],
    search: null,
    groups: [],
    menu: false,
    menu2: false,
    modal: false,
    isValid: false,
    isLoading: false,
    typeForm: "",
    terminals: [],
    cities: [],

    checkbox: false,
    tab: 1,
    dialog: true,
    categoryItems: ["Lorem ipsum", "Lorem ipsum2"],
    deselected: false,
    form: {
      contents: [],
    },
    selectedTerminals: [],
    selectedCities: [],
    fileName: "",
  }),

  watch: {
    content: {
      handler: function () {
        if (this.form.contents) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      },
      deep: true,
    },
    selectedTerminals: {
      handler: function () {
        if (this.selectedTerminals.length > 0) {
          console.log(this.selectedTerminals);
        }
      },
      deep: true,
    },
    date() {
      this.content.initialDate = this.formatDate(this.date);
    },
    date2() {
      this.content.finalDate = this.formatDate(this.date2);
    },
  },

  components: {
    ValidationObserver,
    // BaseInputValidator,
    BaseDialog,
    Content,
    vMultiselectListbox,
  },

  methods: {
    async handleCities(e) {
      console.log(e);
      this.selectedCities = e;
      this.terminals = await TerminalService.getTerminals({
        "location.state": this.selectedCities,
        pagination: false,
      });
    },
    async newItem(event) {
      if (!this.groups.includes(event.at(-1)) && event.length > 0) {
        this.groups.push(event);
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    selectAll() {
      this.form.days = this.schedulingItems;
    },
    deselectAll() {
      this.form.days.deselect = this.deselected;
    },
    async handleFile(e, type) {
      for (let element of e) {
        let content = cloneDeep(this.content);
        const thumb = await this.getVideoCover(element, 5.5);
        await this.createBase64File(element, content, type);
        await this.createBase64File(thumb, content, "thumbnail");
        console.log(content.name);
        this.form.contents.push(content);
      }
    },
    async createBase64File(fileObject, content, type) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (type === "thumbnail") {
            content.thumbnail = e.target.result;
          } else {
            content.name = fileObject.name;
            content.file = e.target.result;
          }
          resolve(true);
        };
        reader.readAsDataURL(fileObject);
      });
    },

    async sendContentInfo() {
      try {
        let contents = [];
        await Promise.all(
          this.form.contents.map(async (element) => {
            element.terminals = this.selectedTerminals;
            element.progress = 5;
            let content = await ContentService.createContent(element);
            element.progress = 100;
            contents.push(content);
          })
        ).then(() => {
          return contents[0];
        });
      } catch (error) {
        console.error(error);
      }
    },
    async verifyTypeForm() {
      this.form.contents = this.form.contents.map((item) => {
        item.initialDate = this.content.initialDate;
        item.finalDate = this.content.finalDate;
        return item;
      });
      console.log(this.form.contents);
      this.isLoading = true;
      if (this.typeForm === "EditContent") {
        const content = await this.updateContent();
        if (this.component) {
          this.$emit("sendedNewContent", true);
        } else {
          this.$router.push({ name: "Content" });
        }
        this.$emit("created", { content: content, typeForm: this.typeForm });
      } else {
        const content = await this.sendContentInfo();
        if (this.component) {
          this.$emit("sendedNewContent", true);
        } else {
          this.$router.push({ name: "Content" });
        }
        this.$emit("created", { content: content, typeForm: this.typeForm });
      }
      this.isLoading = false;
    },
    async closeModal() {
      if (this.component) {
        this.$emit("closeModal", true);
      } else {
        this.$router.push({ name: "Content" });
      }
    },
    async updateContent() {
      try {
        const content = await ContentService.updateContent(
          this.form,
          this.$route.params.id
        );
        return content;
      } catch (error) {
        console.error(error);
      }
    },
    async dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    getVideoCover(file, seekTo = 0.0) {
      return new Promise((resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement("video");
        videoPlayer.setAttribute("src", URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener("error", (ex) => {
          reject("error when loading video file", ex);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener("loadedmetadata", () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
            reject("video is too short.");
            return;
          }
          this.content.duration = videoPlayer.duration;
          // delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          // extract video thumbnail once seeking is complete
          videoPlayer.addEventListener("seeked", () => {
            // define a canvas to have the same dimension as the video
            const canvas = document.createElement("canvas");
            canvas.width = videoPlayer.videoWidth;
            canvas.height = videoPlayer.videoHeight;
            // draw the video frame to canvas
            const ctx = canvas.getContext("2d");
            ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
            // return the canvas image as a blob
            ctx.canvas.toBlob(
              (blob) => {
                resolve(blob);
              },
              "image/jpeg",
              0.75 /* quality */
            );
          });
        });
      });
    },
  },
  async mounted() {
    this.typeForm = this.$route.name;
    this.cities = await TerminalService.getStates();
    this.terminals = await TerminalService.getTerminals({ pagination: false });
    if (this.typeForm === "EditContent") {
      const content = await ContentService.getContentById(
        this.$route.params.id
      );
      const file = await this.dataURLtoFile(
        content.data.file,
        content.data._id + ".mp4"
      );
      // print out the result image blob
      this.form = cloneDeep(content.data);
      this.form.fileName = file;
    }
  },
};
</script>
