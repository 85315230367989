<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="inputValue"
          v-on="on"
          :placeholder="placeholder"
          rounded
          filled
          clearable
          hide-details
          dense
        ></v-text-field>
      </template>
      <v-card>
        <v-card-text>
          <v-date-picker
            v-model="selectedDate"
            :picker-date.sync="pickerDate"
            no-title
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Select date",
    },
    value: String, // data no formato ISO 8601
  },
  data() {
    return {
      inputValue: "",
      pickerDate: null,
    };
  },
  computed: {
    selectedDate: {
      get() {
        return this.pickerDate;
      },
      set(value) {
        this.pickerDate = value;
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        const date = new Date(newValue);
        this.pickerDate = date;
        this.inputValue = date.toLocaleDateString();
      } else {
        this.pickerDate = null;
        this.inputValue = "";
      }
    },
    pickerDate(newValue) {
      if (newValue) {
        const date = new Date(newValue);
        date.setUTCHours(12, 0, 0, 0);
        this.inputValue = date.toLocaleDateString();
      } else {
        this.inputValue = "";
      }
    },
  },
};
</script>
