<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-card @click="oneClick" v-bind="attrs" v-on="on" class="elevation-1">
        <div>
          <v-img
            class="rounded-t-lg"
            v-if="content.type === 'VIDEO'"
            width="100%"
            :height="height"
            :src="content.thumbnail"
          >
            <v-app-bar v-if="eye" flat color="rgba(0, 0, 0, 0)">
              <v-btn
                color="white"
                icon
                @click="$emit('viewContent', content._id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </v-app-bar>
          </v-img>
          <v-img
            class="rounded-t-lg"
            v-else-if="content.type === 'RSS'"
            width="100%"
            height="100px"
            src="../../../../public/img/RSS.png"
          ></v-img>
          <v-img
            class="rounded-t-lg"
            v-else-if="content.type === 'INSTAGRAM'"
            width="100%"
            height="100px"
            src="../../../../public/img/Instagram.jpg"
          ></v-img>
        </div>

        <v-list-item v-if="label">
          <v-list-item-title class="text-body">
            {{ content.name }}
          </v-list-item-title>
        </v-list-item>
      </v-card>
    </template>
    <p class="mb-0">
      {{ content.name }}
    </p>
    <p class="mb-0">Duração: {{ content.duration }}</p>
  </v-tooltip>
</template>
<script>
import {} from "../../../../public/img/RSS.png";
export default {
  props: {
    label: { type: Boolean, default: true },
    eye: {
      type: Boolean,
      default: true,
    },
    content: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: "100px",
    },
  },
  data() {
    return { result: [], delay: 700, clicks: 0, timer: null, fileType: "" };
  },
  methods: {
    oneClick(event) {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.result.push(event.type);
          this.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.$emit("dbClick", this.content);
        this.clicks = 0;
      }
    },
  },
};
</script>
